import CloseIcon from "@mui/icons-material/Close";
import type { BoxProps } from "@mui/material";
import {
  Box,
  Dialog as DialogRoot,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { ComponentProps } from "react";

type RootProps = ComponentProps<typeof DialogRoot> & {
  variant: "alert" | "normal";
  hideCloseButton?: boolean;
};

function Root({ variant, maxWidth, fullWidth, hideCloseButton, ...props }: RootProps) {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const closeButton = hideCloseButton ? undefined : (
    <IconButton
      className="MuiDialog-close-icon"
      type="button"
      aria-label="close"
      onClick={(event) => props.onClose?.(event, "backdropClick")}
      sx={{ position: "absolute", right: 8, top: 8 }}
    >
      <CloseIcon />
    </IconButton>
  );

  const dialogProps = {
    ...props,
    className:
      (variant === "alert" ? "MuiDialog-alert " : "MuiDialog-normal ") + (props.className ?? ""),
  };

  // xs breakpoint
  if (variant === "normal" && !isSmScreen) {
    return (
      <Drawer anchor="bottom" {...dialogProps}>
        {props.children}
        {closeButton}
      </Drawer>
    );
  }

  return (
    <DialogRoot fullWidth={fullWidth} maxWidth={maxWidth} {...dialogProps}>
      {props.children}
      {closeButton}
    </DialogRoot>
  );
}

function Icon({ sx, ...props }: BoxProps) {
  return (
    <Box className="MuiDialogIcon-root" sx={{ display: "flex", justifyContent: "center", pt: 4 }}>
      <Box
        className="MuiDialogIcon-icon"
        sx={{
          width: 48,
          height: 48,
          p: 1,
          borderRadius: 9999,
          bgcolor: "blue.0",
          color: "blue.4",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...sx,
        }}
        {...props}
      />
    </Box>
  );
}

export const Dialog = {
  Root: Root,
  Actions: DialogActions,
  Content: DialogContent,
  ContentText: DialogContentText,
  Title: DialogTitle,
  Icon,
};
